export const ColorTheme = {
  Transparent: 'transparent',

  Black: 'var(--color-black)',
  White: 'var(--color-white)',
  Cycle: 'var(--color-cycle)',

  Grey50: 'var(--color-grey-50)',
  Grey100: 'var(--color-grey-100)',
  Grey200: 'var(--color-grey-150)',
  Grey300: 'var(--color-grey-200)',
  Grey400: 'var(--color-grey-350)',
  Grey500: 'var(--color-grey-500)',
  Grey600: 'var(--color-grey-550)',
  Grey700: 'var(--color-grey-600)',
  Grey750: 'var(--color-grey-750)',
  Grey800: 'var(--color-grey-800)',
  Grey850: 'var(--color-grey-850)',
  Grey900: 'var(--color-grey-950)',

  Blue100: 'var(--color-blue-100)',
  Blue200: 'var(--color-blue-200)',
  Blue300: 'var(--color-blue-300)',
  Blue400: 'var(--color-blue-400)',
  Blue500: 'var(--color-blue-500)',
  Blue600: 'var(--color-blue-600)',
  Blue700: 'var(--color-blue-700)',
  Blue800: 'var(--color-blue-800)',
  Blue900: 'var(--color-blue-900)',

  Green100: 'var(--color-green-100)',
  Green200: 'var(--color-green-200)',
  Green300: 'var(--color-green-300)',
  Green400: 'var(--color-green-400)',
  Green500: 'var(--color-green-500)',
  Green600: 'var(--color-green-600)',
  Green700: 'var(--color-green-700)',
  Green800: 'var(--color-green-800)',
  Green900: 'var(--color-green-900)',

  Yellow100: 'var(--color-yellow-100)',
  Yellow200: 'var(--color-yellow-200)',
  Yellow300: 'var(--color-yellow-300)',
  Yellow400: 'var(--color-yellow-400)',
  Yellow500: 'var(--color-yellow-500)',
  Yellow600: 'var(--color-yellow-600)',
  Yellow700: 'var(--color-yellow-700)',
  Yellow800: 'var(--color-yellow-800)',
  Yellow900: 'var(--color-yellow-900)',

  Orange100: 'var(--color-orange-100)',
  Orange200: 'var(--color-orange-200)',
  Orange300: 'var(--color-orange-300)',
  Orange400: 'var(--color-orange-400)',
  Orange500: 'var(--color-orange-500)',
  Orange600: 'var(--color-orange-600)',
  Orange700: 'var(--color-orange-700)',
  Orange800: 'var(--color-orange-800)',
  Orange900: 'var(--color-orange-900)',

  OrangeRed100: 'var(--color-coral-100)',
  OrangeRed200: 'var(--color-coral-200)',
  OrangeRed300: 'var(--color-coral-300)',
  OrangeRed400: 'var(--color-coral-400)',
  OrangeRed500: 'var(--color-coral-500)',
  OrangeRed600: 'var(--color-coral-600)',
  OrangeRed700: 'var(--color-coral-700)',
  OrangeRed800: 'var(--color-coral-800)',
  OrangeRed900: 'var(--color-coral-900)',

  Red100: 'var(--color-red-100)',
  Red200: 'var(--color-red-200)',
  Red300: 'var(--color-red-300)',
  Red400: 'var(--color-red-400)',
  Red500: 'var(--color-red-500)',
  Red600: 'var(--color-red-600)',
  Red700: 'var(--color-red-700)',
  Red800: 'var(--color-red-800)',
  Red900: 'var(--color-red-900)',

  Pink100: 'var(--color-pink-100)',
  Pink200: 'var(--color-pink-200)',
  Pink300: 'var(--color-pink-300)',
  Pink400: 'var(--color-pink-400)',
  Pink500: 'var(--color-pink-500)',
  Pink600: 'var(--color-pink-600)',
  Pink700: 'var(--color-pink-700)',
  Pink800: 'var(--color-pink-800)',
  Pink900: 'var(--color-pink-900)',

  Purple100: 'var(--color-purple-100)',
  Purple200: 'var(--color-purple-200)',
  Purple300: 'var(--color-purple-300)',
  Purple400: 'var(--color-purple-400)',
  Purple500: 'var(--color-purple-500)',
  Purple600: 'var(--color-purple-600)',
  Purple700: 'var(--color-purple-700)',
  Purple800: 'var(--color-purple-800)',
  Purple900: 'var(--color-purple-900)',

  Violet100: 'var(--color-violet-100)',
  Violet200: 'var(--color-violet-200)',
  Violet300: 'var(--color-violet-300)',
  Violet400: 'var(--color-violet-400)',
  Violet500: 'var(--color-violet-500)',
  Violet600: 'var(--color-violet-600)',
  Violet700: 'var(--color-violet-700)',
  Violet800: 'var(--color-violet-800)',
  Violet900: 'var(--color-violet-900)',
};
