export function isCloudinarySrc(src: string) {
  return src.includes('res.cloudinary.com');
}

export function getOptimizedImgSrc(src: string | undefined, width?: number) {
  if (!src) return;
  if (!isCloudinarySrc(src)) return src;

  return enhanceCloudinarySrc(src, getSizeOptimizations(width ? width * 2 : undefined));
}

function getSizeOptimizations(width?: number) {
  if (!width) return 'q_auto,f_auto';

  return `w_${width},c_limit,q_auto,f_auto`;
}

function enhanceCloudinarySrc(src: string, transformations: string) {
  return `${src.replace('/upload/', `/upload/${transformations}/`)}`;
}
